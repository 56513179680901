import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import   "bootstrap/dist/css/bootstrap.css";
function App() {
  return (
    <div className="App">
       <Home></Home>
    </div>
  );
}

export default App;
