import React, {ChangeEvent, useEffect, useState} from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from 'react-bootstrap/Modal';
import {BsFillCloudUploadFill} from "react-icons/bs";
import {Form} from "react-bootstrap";
import FileService from "../Services/FileService";
import {AlertMessage} from "../Types/AlertMessages";
import {FileItem} from "../Types/FileItem";

type Props = {
    alertHandler: any
}

function FileUpload(props: Props) {
    const [show, setShow] = useState(false);
    const [fileUpload, setFileUpload] = useState<File>();
    const [fileData, setFileData] = useState<File>();

    const dismissDialog = () => {
        setShow(false);
        setFileData(undefined);
        props.alertHandler([{
            title: 'FileUpload Cancelled...',
            message:'',
            type:'warning'
        } as AlertMessage])
    };
    const completeUpload = () => {
        setShow(false);
        if (fileUpload) {
            apiRequestData(fileUpload).then((result: any) => {
                setFileData(undefined);
                setFileData(result);
            })
        }
    };

    const showUploadSuccessAlert = (file: FileItem) =>{
        props.alertHandler([{
            title: 'Successfully uploaded your file',
            message:file.publicUrl,
            type:'info'
        } as AlertMessage])
    }
    const callFileUpload = (data: any) => {
        if (data) {
            FileService.saveFile(data).then((response) => {
               showUploadSuccessAlert(response.data);
            })
        }
    }
    const handleFileSelection = ($event: ChangeEvent<HTMLInputElement>) => {
        const files = $event.target.files;
        if (files && files.length > 0) {
            setFileUpload(files[0]);
            handleShow();
        }
    }

    const apiRequestData = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({
            content: reader.result,
            name: file.name,
            type: file.type
        });
        reader.onerror = reject;
    });

    useEffect(() => {
        callFileUpload(fileData);
    }, [fileData])
    const handleFileUpload = ($event: ChangeEvent<any>) => {
        document.getElementById("fileUpload")?.click()
    }
    const handleShow = () => setShow(true);

    return <>
        <div>
            <input type="file" hidden id="fileUpload" onChange={handleFileSelection}/>
            <Button onClick={handleFileUpload}
                    className="d-flex flex-column justify-content-center align-items-center">
                <BsFillCloudUploadFill/>
            </Button>
            <Modal show={show} onHide={completeUpload}>
                <Modal.Header closeButton>
                    <Modal.Title>Would you like to tag your file?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tag-input-row">
                        <div className="text">This is useful for search and grouping the results?</div>
                        <div className="text-sm">Leave blank if you don't want to add tags</div>
                        <Form.Control type="email" placeholder="enter tag names..."/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={dismissDialog}>
                        Cancel Upload
                    </Button>
                    <Button variant="primary" onClick={completeUpload}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    </>
}

export default FileUpload;
