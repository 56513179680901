import React, {useState} from "react";
import AppNav from "./NavBar";
import Files from "./Files";
import SearchBar from "./Search/SearchBar";
import FileUpload from "./FileUpload";
import DismissibleAlert from "./Alert/Alert";
import {AlertMessage} from "../Types/AlertMessages";

function Home() {
    const [searchTerm, setSearchTerm] = useState("");
    const [messages, setMessages] = useState<AlertMessage[]>([]);

    const handleMessages = (messages: AlertMessage[]) => {
        setMessages(messages);
    }
    const handleSearch = ($event: any) => setSearchTerm($event.target.value)

    return <>
        <AppNav></AppNav>
        <div className="fileContent d-flex flex-column mt-3">
            <div className="contentHeader d-flex justify-content-between">
                <FileUpload alertHandler={handleMessages}></FileUpload>
                <SearchBar onSearchTermChange={handleSearch}></SearchBar>
            </div>
            <DismissibleAlert alertMessages={messages}></DismissibleAlert>
            <Files searchTerm={searchTerm}></Files>
        </div>
    </>
}

export default Home;
