import React, {useEffect, useState} from "react";
import FileService from "../Services/FileService";
import {FileItem} from "../Types/FileItem";
import {
    BsFileImage,
    BsFillClipboardCheckFill,
    BsDownload,
    BsClipboardCheck,
    BsCheckCircleFill,
    BsTrash
} from "react-icons/bs";
import "./Files.css";
import fileService from "../Services/FileService";

type Props = {
    searchTerm: string;
};

function Files({searchTerm}: Props) {
    const [files, setFiles] = useState<FileItem[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<FileItem[]>([]);

    const fetchFiles = async () => {
        try {
            const response = await FileService.fetchFiles();
            if (response && response.status === 200 && response.data && response.data.content) {
                const contentData = response.data.content; // Extract the "content" property from the response data
                setFiles(contentData); // Set the "content" data in your component's state
            } else {
                console.error("Failed to fetch files. Response:", response);
            }
        } catch (error) {
            console.error("Error fetching files:", error);
        }
    };

    const deleteFileByKey = async (keyToDelete: string) => {
        try {
            fileService.deleteByKey(keyToDelete).then((result) => {
                const updatedFiles = files.filter((file) => file.key !== keyToDelete);
                setFiles(updatedFiles);
                const updatedFilteredFiles = filteredFiles.filter(
                    (file) => file.key !== keyToDelete
                );
                setFilteredFiles(updatedFilteredFiles);
            })
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };
    const filterFiles = (searchTerm: string) => {
        let fs: FileItem[] = [];
        if (!searchTerm) {
            fs = [...files];
        } else {
            fs = files.filter((f) => f.key.includes(searchTerm));
        }
        setFilteredFiles(fs);
    };

    useEffect(() => {
        fetchFiles().then(() => {
        });
    }, [searchTerm]);

    const copyToClipboard = (item: any) => {
        navigator.clipboard.writeText(item.publicUrl).then(() => {
            setCopiedStatus(item, true);
            setTimeout(() => {
                setCopiedStatus(item, false);
            }, 5000);
        });
    }
    const setCopiedStatus = (inputFile: FileItem, copied: boolean) => {
        const updatedFiles = files.map(file => {
            if (file === inputFile) {
                return {...inputFile, copied: copied};
            }
            return file;
        });
        setFiles(updatedFiles);
    }

    useEffect(() => {
        filterFiles(searchTerm);
    }, [searchTerm, files]);

    return (
        <div className="files">
            {filteredFiles.length === 0 ? (
                <b>No files available</b>
            ) : (
                <>
                    {filteredFiles.map((file) => (
                        <div className="fileItem d-flex gap-1 justify-content-between" key={file.key}>
                            <div className="fileName">
                                <span><BsFileImage/>  </span>
                                <span>{file.key}</span>
                                <span>({file.size}kb)</span>
                            </div>
                            <div className="file-actions d-flex mx-2">
                                <div className="copyfile mx-2" onClick={() => copyToClipboard(file)}>
                                    {file.copied && <BsCheckCircleFill className="text-success"/>}
                                    <BsClipboardCheck/> Copy Link
                                </div>
                                <div className="downloadfile mx-2"><BsDownload/> Download</div>
                                <div className="deletefile mx-2" onClick={() => deleteFileByKey(file.key)}>
                                    <BsTrash/> Delete
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default Files;
