import axios from "axios";

const http = axios.create({
    baseURL: 'https://files.magicproc.co.za/api/files',
    headers: {
        'Content-Type': 'application/json'
    }
});

const http2=  axios.create({
    baseURL: 'https://files.magicproc.co.za/api/files',
    headers: {
        'Content-Type': 'application/json'
    }
});

export default {
    http,
    http2
};

//
//
// import axios from "axios";
//
// const baseApiUrl = process.env.BACKEND_API;
// const http = axios.create({
//     baseURL: `${baseApiUrl}/files`,
//     headers: {
//         'Content-Type': 'application/json'
//     }
// });
//
// const http2=  axios.create({
//     baseURL: `${baseApiUrl}/files`,
//     headers: {
//         'Content-Type': 'application/json'
//     }
// });
//
// export default {
//     http,
//     http2
// };
