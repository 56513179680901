import {useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import {AlertMessage} from "../../Types/AlertMessages";

type Props = {
    alertMessages: AlertMessage[]
}

function DismissibleAlert({alertMessages}: Props) {

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (alertMessages.length > 0) {
            setShow(true)
        }
    }, [alertMessages]);

    if (show) {
        return (
            <>
                {alertMessages.map(alert =>
                    <Alert variant={alert.type} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>
                            {alert.title ?? <> {alert.title} </>}
                        </Alert.Heading>
                        <p>{alert.message}</p>
                    </Alert>
                )}
            </>
        );
    }
    return <b></b>;
}

export default DismissibleAlert;
