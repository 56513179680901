import service from './HttpCommons';

const fetchFiles = () => {
    return service.http.get<any>('/pages?page=0&size=1500');
}

const saveFile = (fileData: any) => {
    return service.http2.post<any>('/upload', fileData);
}

const deleteByKey = (fileKey: string) => {
    return service.http.delete<any>(`/${fileKey}`);
}

const FileService = {
    fetchFiles,
    saveFile,
    deleteByKey
}
export default FileService;
