import { Button } from "react-bootstrap";
import "./SearchBar.css"
import * as Icon from 'react-bootstrap-icons';


type Props = {
    onSearchTermChange : any
}
function SearchBar({ onSearchTermChange} : Props) {
    return <>
        <div className="searchbar">
            <input onChange={onSearchTermChange}></input>
            <Icon.Search />
        </div>
    </>
}

export default SearchBar;